import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/fonts/iconfont.css'
import './assets/css/common.css'
import './assets/css/communal.css'
import url from '@/utils/url'

/* 本地 */
// Vue.prototype.BASE_URL = 'http://192.168.1.200:2000'
/* 测试环境 */
// Vue.prototype.BASE_URL = 'https://test.yuchen2022.com:8700'
/* 正式环境 */
Vue.prototype.BASE_URL = 'https://os.yuchen2022.com:8701'

Vue.prototype.$url = url

Vue.config.productionTip = false

// 路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/Login') return next()
  const tokenStr = window.localStorage.getItem('token')
  if (!tokenStr) return next('/Login')
  next()
})

Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
